<template>
  <div>
    <span v-if="params.value" :class="`price_${uid}`"
      >{{ usdCryptoPrice((params.value || '').toLocaleString()) }}
    </span>
    <span class="text-gray-400" v-else>-</span>
  </div>
</template>

<script setup>
import { usdCryptoPrice } from '@/composeables/filters';
import { computed } from 'vue';

const props = defineProps({
  params: Object
});
const uid = computed(() => {
  return props.params.data?.uid;
});
</script>
